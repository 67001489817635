import {
    Button,
    Grid,
    Typography
}                        from "@mui/material";
import React, {
    useEffect,
    useRef,
    useState
}                        from "react";
import {
    Boorprofiel,
    DeactivateBoorprofielCommand,
    DeactivateEnergySystemCommand,
    Delete,
    EnergySystem,
    EnergySystemDetail,
    IGStyle,
    UpdateEnergySystem,
    UpdateEnergySystemStatus,
    UpdateEnergySystemStatusToezicht,
} from "../../constants";
import {useGobesContext} from "../../context/GobesContextProvider";
import {submitObject}    from "../../util";

const EnergySystemBlock = () =>
{
    const { setModalVisible, setModalAction,setEnergyDetails,setFormData
        , refreshResource, setRefreshResource, energyDetails,companyDetails,
        setAlertVisible,setAlertText,setActivePage,setDeleteCallback,
    } = useGobesContext()

    const RDRef = useRef<any>()

    useEffect(() =>
    {
        switch (refreshResource)
        {
            case EnergySystemDetail:
                setTimeout(() => RDRef.current?.refresh(), 1000)
                break
        }
        setRefreshResource("")
    }, [refreshResource]);

    function OnClickEnergySystemBewerken()
    {
        setFormData(RDRef.current.data)
        setEnergyDetails(RDRef.current.data)
        setModalVisible(true)
        setModalAction(UpdateEnergySystem)

    }

    function OnClickEnergySystemStatusBewerken()
    {
        setFormData(RDRef.current.data)
        setEnergyDetails(RDRef.current.data)
        setModalVisible(true)
        setModalAction(UpdateEnergySystemStatus)

    }

    function OnClickEnergySystemToezichtBewerken()
    {
        setFormData(RDRef.current.data)
        setEnergyDetails(RDRef.current.data)
        setModalVisible(true)
        setModalAction(UpdateEnergySystemStatusToezicht)

    }


    function DeleteObject() {
        submitObject({id: RDRef.current?.data.id},  companyDetails?.id, DeactivateEnergySystemCommand).then(async (event: any) =>
        {
            HandleSubmit(event)
        })
        setActivePage((activePage: number) => activePage - 1)

        setEnergyDetails(undefined)
        setRefreshResource(EnergySystemDetail)
        setRefreshResource(EnergySystem)
    }
    async function HandleSubmit(event: any)
    {
        //In case of a status that isnt 200, we most likely have received an error message.
        //So we display this as alert in the Modal
        if (event.status !== 200){
            const response = await event.json()
            setAlertVisible(true)
            setAlertText(response.messages.reduceRight((acum: any, current: any) => acum.concat(current)))
        }
    }

    const handleStartDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        setModalVisible(true)
        setModalAction(Delete)
        setDeleteCallback({delete: DeleteObject} )
    };

    return (<>
        <Grid container justifyContent="center" direction={"column"}>
            <Grid container className={"rm-container"} direction={"row"}>
                <Grid container>
                    <Typography variant="h4" marginRight={"auto"}>
                        Energie Systeem
                    </Typography>
                </Grid>

                <Grid item xs={8} container alignItems={"stretch"} className={"containerspacing"}>
                        {/*// @ts-ignore*/}
                        <ig-resource-detail lang="nl-NL" ref={RDRef} class={"ig-detail-remove-2rows"} style={IGStyle} resource-detail-id="EnergySystemRD" resource-id={energyDetails?.id}/>
                </Grid>

                <Grid container item className={"button-container"} xs={3} marginLeft={1} justifyContent={"flex-start"}>

                    <Grid item xs={4}>
                        <Grid container direction="column" item>
                            <Button variant={"contained"} onClick={OnClickEnergySystemBewerken}>Energiesysteem Bewerken</Button>
                            <Button variant={"contained"} onClick={OnClickEnergySystemStatusBewerken}>Energiesysteem status Bewerken</Button>
                            <Button variant={"contained"} onClick={OnClickEnergySystemToezichtBewerken}>Energiesysteem status toezicht Bewerken</Button>
                            <Button variant={"contained"} onClick={handleStartDelete}>Energiesysteem Verwijderen</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    </>)
}

export default EnergySystemBlock